/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "./layout.css"

const Layout = ({ title, activePageId, reportPages, reportId, children }) => {
  const mainLayout = (
    <>
      <Header
        siteTitle={title}
        reportId={reportId}
        activePageId={activePageId}
        reportPages={reportPages}
      />
      <div style={{ height: 120 }}></div>
      <div
        style={{
          margin: `0 auto`,
        }}
        className='cx-narrow-container'
      >
        <main>{children}</main>
      </div>
    </>
  )

  return mainLayout
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default Layout
