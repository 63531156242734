import React, { useState, useEffect } from "react"
import { useFirebase } from "../hooks/useFirebase"
import getAuthInstance from "./auth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons"

const Logout = () => {
  const firebase = useFirebase()

  const [firebaseInstance, setFirebaseInstance] = useState(null)

  useEffect(() => {
    if (firebase === null) {
      setFirebaseInstance(null)
    } else {
      setFirebaseInstance(firebase)
    }
  }, [firebase])

  async function doSignout() {
    try {
      await firebaseInstance.auth().signOut()
      getAuthInstance().setUser(null)
    } catch (e) {
      console.log(e)
    }
  }

  function handleClick() {
    if (firebaseInstance) {
      doSignout()
    }
  }

  return (
    <>
      <span
        style={{
          color: "#FFFFFF",
          fontSize: "0.85028rem",
          marginRight: 20,
        }}
      >
        {getAuthInstance().getUser().email}
      </span>
      <button
        style={{
          fontSize: "0.85028rem",
        }}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>
        Sign Out
      </button>
    </>
  )
}

export default Logout
