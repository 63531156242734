const config = {
  apiKey: "AIzaSyB-5vwmNihAO3uZDnV4yY_nxn1y7qa0H2k",
  authDomain: "celtx-dashboard.firebaseapp.com",
  databaseURL: "https://celtx-dashboard.firebaseio.com",
  projectId: "celtx-dashboard",
  storageBucket: "celtx-dashboard.appspot.com",
  messagingSenderId: "438892779533",
  appId: "1:438892779533:web:b4a52bb4b985f8260d1d44",
}

let firebase

let firebaseApp

export const getFirebase = () => {
  if (typeof window !== "undefined") {
    if (firebase) return firebase
    if (firebaseApp !== undefined) {
      firebase = firebaseApp.initializeApp(config)
      firebase.auth()
      return firebase
    }
  }

  return null
}

export const setFirebaseApp = app => {
  firebaseApp = app
}

export const getFirebaseApp = () => {
  return firebaseApp
}
