import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"

const PreviousPageLink = ({ path }) => {
  return <PageLink path={path} arrowDirection="left" />
}

const NextPageLink = ({ path }) => {
  return <PageLink path={path} arrowDirection="right" />
}

const PageLink = ({ path, arrowDirection }) => {
  let ret = (
    <FontAwesomeIcon
      icon={arrowDirection === "right" ? faChevronRight : faChevronLeft}
    />
  )

  if (path) {
    ret = (
      <Link to={path}>
        <FontAwesomeIcon
          icon={arrowDirection === "right" ? faChevronRight : faChevronLeft}
        />
      </Link>
    )
  }

  return <div className="cx-page-changer-page-link-wrapper">{ret}</div>
}

const PageChanger = ({ activePageId, reportPages }) => {
  let index
  let currentPageName
  let activeIndex = 0
  for (index = 0; index < reportPages.length; ++index) {
    if (reportPages[index].id === activePageId) {
      activeIndex = index
      currentPageName = (
        <div className="cx-page-changer-page-title">
          {reportPages[index].name} ( Page {index + 1} of {reportPages.length} )
        </div>
      )
    }
  }

  let previousLink
  if (activeIndex > 0) {
    previousLink = reportPages[activeIndex - 1].path
  }

  let nextLink
  if (activeIndex < reportPages.length - 1) {
    nextLink = reportPages[activeIndex + 1].path
  }

  return (
    <div className="cx-page-changer">
      <PreviousPageLink path={previousLink} />
      {currentPageName}
      <NextPageLink path={nextLink} />
    </div>
  )
}

export default PageChanger
