import React, { useState } from "react"
import { getFirebase, setFirebaseApp } from "../components/Firebase"

export function useLoadFirebase() {
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    const app = import("firebase/app")
    const auth = import("firebase/auth")

    Promise.all([app, auth]).then(values => {
      setFirebaseApp(values[0])
      setLoaded(true)
    })
  }, [])

  return loaded
}

export function useFirebase() {
  const [instance, setInstance] = useState(null)

  React.useEffect(() => {
    setInstance(getFirebase())
  }, [])

  return instance
}
