import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import PageChanger from "./page-changer"
import getAuthInstance from "./auth"
import Logout from "./logout"
import Logo from "./logo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

const Header = ({ siteTitle, activePageId, reportId, reportPages }) => {
  let dataStudioLink = null
  if (reportId && reportPages) {
    dataStudioLink = (
      <span className="cx-header-external-link">
        <a
          title="Open in Google Data Studio"
          href={`https://datastudio.google.com/u/0/reporting/${reportId}/page/${reportPages[0].id}`}
          target={"_blank"}
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </a>
      </span>
    )
  }

  let pageSelect = null
  if (reportPages && reportPages.length > 1) {
    pageSelect = (
      <div
        style={{
          backgroundColor: "#4a4a4a",
          width: "100%",
        }}
      >
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1200,
            padding: `0.45rem 0rem`,
          }}
        >
          <PageChanger activePageId={activePageId} reportPages={reportPages} />
        </div>
      </div>
    )
  }

  let logout

  if (getAuthInstance() && getAuthInstance().isLoggedIn()) {
    logout = <Logout />
  }

  return (
    <header
      style={{
        background: `black`,
        marginBottom: `1.45rem`,
        position: "fixed",
        width: "100%",
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1200,
          padding: `0.45rem 0rem`,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ width: 120 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            <Logo />
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ width: 300 }}>
            <span style={{ color: "#FFFFFF" }}>{siteTitle}</span>
            {dataStudioLink}
          </div>
          <div>{logout}</div>
        </div>
      </div>
      {pageSelect}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
