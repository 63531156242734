import { EventEmitter } from "events"

class Auth extends EventEmitter {
  constructor() {
    super()
    this.currentUser = null
  }

  isLoggedIn = () => {
    return this.currentUser != null
  }

  setUser = user => {
    this.currentUser = user
    this.emit("auth_changed")
  }

  getUser = () => {
    return this.currentUser
  }
}

let auth

export default function() {
  if (typeof window !== "undefined") {
    if (auth) return auth
    auth = new Auth()
    return auth
  }
}
